<template>
  <v-card class="mx-auto my-1" :loading="loading">
    <v-card-actions>
      <v-btn @click="$router.go(-1)" small text>
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </v-card-actions>

    <v-img
      class="white--text align-end"
      height="200px"
      :src="imgurl"
    >
    </v-img>
    <v-card-subtitle class="pb-0 text-h3 font-weight-black text-center text-decoration-underline text--primary">
      {{ title.desc }}  — {{ title.variety }}
    </v-card-subtitle>
    <v-card-text class="text--primary">
      <v-data-table
        :headers="headers"
        :items="farmers"
          class="elevation-0 my-1"
        :loading="loading"
      >
        <template slot="no-data">
          <div>No Record</div>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon large color="green" @click="getFarmerContact(item)">
            mdi-information
          </v-icon>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";

export default {
   name:"livestockfarmer",
  data() {
    return {
      title: [],
      farmers: [],
      headers: [
        {
          text: "Farmer",
          value: "surname",
        },
        { text: "Barangay", value: "brgy.brgyname" },
        { text: "Municipal", value: "municipal.munname" },
        { text: "Available", value: "available" },
        { text: "Age", value: "age" },
        { text: "Contacts", value: "actions", sortable: false },
      ],
      farmgate:[],
      loading:false,
      imgurl:''
    };
  },

  computed: {
    ...mapGetters(["livestock/getSelected", "livestock/getFarmers","livestock/getFarmGate"])
  },

  mounted() {
    this.getLoadData()
  },

  methods: {
    async getLoadData(){
      this.loading=true
      this.title = this["livestock/getSelected"];
      this.farmers=await this["livestock/getFarmers"];
      this.farmgate=await this["livestock/getFarmGate"]
      this.imgurl=this.farmgate.imgurl
      this.loading=false
    },
    getFarmerContact(item){
        this.$store.dispatch("livestock/onSelectFarm",item)
        this.$router.push({name:"Livestock Farmer Contact"})
    }

  },
};
</script>
